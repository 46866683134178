import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Directories from "../components/Home/Directories";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import ServicesHome from "../components/Home/ContentServicesHome";
import HeroSection from "../components/Home/HeroSection";
import Paletacolor from "../components/global/Paletacolor";
import BlockPrincipal from "../components/block/Block_1";
import BlockSecundary from "../components/block/Block_7";
import VideoPromocional from "../components/global/VideoPromocional";
import ContentServices from "../components/Services/ContentServices";
import GalleryContent from "../components/Gallery/GalleryContent";
import OtherBlock from "../components/block/Block_11";
import BlockTwelve from "../components/block/Block_12";
import ChamberReviews from "../components/reviews/ChamberReviews";


function Home() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">
        {/* <Modal /> */}

        <HeroSection
          urlVideo={'https://firebasestorage.googleapis.com/v0/b/videos-de-stock.appspot.com/o/Welding%2FWelding.mp4?alt=media&token=a454aadd-539b-43af-b0f7-0f7efefe8f06&_gl=1*1tum55j*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5NzgyODcwOC4yMTQuMS4xNjk3ODI5MTE5LjQ4LjAuMA.. '}
        />
        <BlockPrincipal
          text={rpdata?.dbHome?.[0].text}
          image={rpdata?.gallery?.[1]}
          sloganPrincipal={true}
        />

        {/* video promocional */}
        {rpdata?.videosPromo?.[0]?.activo ? (
          <VideoPromocional
            title={rpdata?.dbSlogan?.[0]?.slogan}
            text={rpdata?.dbAbout?.[1]?.text}
            linkVideo={`${rpdata?.videosPromo?.[0].link}`}
            image={`${rpdata?.videosPromo?.[0].img}`}
            vimeoVideo
          />
        ) : null}

        <Directories />
        <BlockTwelve
          image={rpdata?.gallery?.[2]}
          slogan={rpdata?.dbSlogan?.[0]?.slogan}
          text={rpdata?.dbHome?.[1]?.text}
        />
       <BlockSecundary
        title={rpdata?.dbSlogan?.[7].slogan}
        sloganPrincipal={false}
        text={rpdata?.dbAbout?.[2].text}
        image={rpdata?.gallery?.[6]}
        />

        <CounterUp image={rpdata?.gallery?.[3]} />

        <OtherBlock
          image={rpdata?.gallery?.[4]}
          text={rpdata?.dbAbout?.[0].text}
          title={rpdata?.dbSlogan?.[7].slogan}
          listsAbout
        />

        {/* our reviews */}
        {rpdata?.reviews?.isHomeOnly === true ? (
          <div className="w-[95%] lg:w-4/5 mx-auto py-[100px]">
            {/* <div className={`${rpdata?.reviews?.links?.[0]}`}></div> */}
            < ChamberReviews />
          </div>
        ) : null}


        {/* Servicios del home */}
        {rpdata?.simpleWidgets?.[3]?.activo ? (
          <div className="pt-20">
            <ContentServices />
          </div>
        ) : (
          <ServicesHome />
        )}

        {/* stock cuando es OnePages */}

        {/* Paleta de Colores */}
        {rpdata?.simpleWidgets?.[3]?.activo ? (
          <div className="pt-10 pb-28">
            <h2 className="text-center pb-[50px]">
              {rpdata?.labels?.general?.titleGallery}
            </h2>
            <GalleryContent
              galleryImages={
                rpdata?.gallery?.length > 0 ? rpdata?.gallery : rpdata?.gallery
              }
            />
          </div>
        ) : null}
        {rpdata?.brandingExtra?.[0]?.activo ? <Paletacolor /> : null}
        <div
          className="bgFormHome border-y-8 border-black"
          style={{ backgroundImage: `url("${rpdata?.gallery?.[19]}")` }}
        >
          <div className="relative">
            <FormHome />
          </div>
        </div>
        <div className="w-full h-auto bg-white -mt-10">
          <div>
            <Map />
          </div>
        </div>

      </div>
    </BaseLayout>
  );
}

export default Home;
