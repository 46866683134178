import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import ContactInfo from "../components/Contact/ContactInfo_3";
import Map from "../components/Contact/MapContent";
import BlockeSlidenew from "../components/block/BlockeSlidenew";

function Reservation() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Reservation">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Reservation"
          Subheader="Reservation"
          bgimg={`${rpdata?.gallery?.[0]}`}
        />
        <BlockeSlidenew image={rpdata?.gallery?.[0]} />
        <ContactInfo />

        <Map />
      </div>
    </BaseLayout>
  );
}

export default Reservation;
