import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'



const BlockeSlidenew = ({image}) => {
    const { rpdata } = useContext(GlobalDataContext)


    return (
        <div className='py-[150px] bg-cover bg-no-repeat bg-fixed bg-center relative mt-10 '
            style={{ backgroundImage: `url("${image ? image : rpdata?.gallery?.[10]}")` }}
 >
            <div className='text-center text-[20px] text-[#000]  w-4/5 m-auto backdrop-sepia-0 bg-white px-10 py-5 font-[450] font-Calibri lg:w-[70%] '>

                <h2 className='px-10 text-[#211BF8]'>OTHER SERVICES AND EXTRA FEE</h2>
        
            <p className='pl-3'>TO ALL COUSTUMERS, PLEASE READ THE ADDITIONAL CHARGES</p><br/>
            <p className='pl-3'>$4.00 DOLLARS DEPARTURE TAXE FOR RATES LEAVIG O’HARE AND MIDWAY AIPORTS AIRPORTS (EFFECTIVE JANUARY1, 1993 BY BY THE METROPOLITAN PIERE EXPOSITION AUTORITY)</p>
            <p className='paragraphstyles bold' >PASSENGERS ARE RESPONSIBLESFOR TALL</p><br/>
            <p className='pl-3 text-[25px] text-red-600 '>ANCELLATION POLICY:</p>
            <p className='paragraphstyles font-normal' > TO AVOID ANY CHARGES, CANCELLATION REQUIRED AT LEAST 2 HOURS PRIOR TO PICK UP WE DO CHARGE FOR UNUSED RESERVATIONS THAT HAVE NOT BEEN CANCELLED $30.00 TIME PER HOUR WATING TIME RATES DON’T INCLUDE TIPS</p><br/>
            <p className='pl-3 text-[25px]'>CREDIT CARD EXTRA FEE</p>
            <p className='seconparagraphs'>5% Credit Card Fee is applied, when you use any type of credit or debit card.</p>
            <p className='paragraphstyles font-normal' > To avoid this charge, we also accept zelle payment.</p><br/>
            <p className='pl-3 text-[25px]'>Baby Car seat</p>
            <p className='seconparagraphs'>Only with prior request! Rivera’s taxi has a $ 8.00 extra fee Per seat.</p><br/>
            <p className='pl-3 text-[25px]'>Holidays and Weather extra Fee</p>
            <p className='seconparagraphs'>Rivera’s Taxi will add an extra cost of 25% holidays or really bad weather conditions.</p><br/>
            <p className='pl-3 text-[25px]'>Late night Extra Fees</p>
            <p className='seconparagraphs'>We will add an additional cost of 25% for each service completed between 11:30pm to 4:30 am.</p><br/>
            <p className='pl-3 text-[25px]'>Late Night Condition</p>
            <p className='seconparagraphs'> Any order between 12:00 AM and 4:30 AM must be paid in advance.</p><br/>
            <p className='pl-3 text-[25px]'>MINIVAN SERVICE</p>
            <p className='seconparagraphs'>We have comfortable and spacious Minivans taxis for your family trips and with more luggage space. Cab services for: 6 passengers X 6 Suitcase, this taxi services have a 10$ extra fee Per ride.</p><br/>
            <p className='pl-3 text-[25px]'>EXTRA FEE FOR 4TH PASENGER</p>
            <p className='pl-3'>Rivera's Taxi. An additional $5.00 will be added for the 4th passengers traveling</p>
            <p className='paragraphstyles font-normal' >we never charge extra for 3 passengers.<br/><span className='paragraphstyles'>we never charge extra for 3 passengers.THIS APLY TO ALL SERVICES</span></p><br/>
            <p className='pl-3 text-[25px]'>CLEANUP FEE</p>
            <p className='seconparagraphs'>If anything happens to the vehicle, there is a $120.00 cleaning fee for food spills or any other cause that requires cleaning</p><br/>
            <p className='pl-3 text-[25px]'>ALCOHOL IS PROHIBITED IN OUR TAXICABS</p>


            </div>
           
            
        </div>
    )
}

export default BlockeSlidenew;