import React, { useContext } from 'react'
import { GlobalDataContext } from '../context/context';
import { ButtonContent } from './global/boton/ButtonContent';



function RatesCompRec() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div className='mx-5 md:grid grid-cols-2 md:gap-4 sm:grid-cols-3'>
      {
        rpdata?.dbServices?.slice(0, 3).map((serv, index) => {

          return (
            <>
              <div key={index} className={`pb-3 mb-10 border-4 rounded-2xl flex flex-col bg-white bg-opacity-25 backdrop-blur-md`}>
                <div className='flex space-x-3 p-2 h-[200px]'>
                  <div className='my-auto'>
                    <h3 className='text-start capitalize md:text-4xl text-lg font-bold text-white'>{serv.name}</h3>
                    <ButtonContent btnLink='./Contact' btnName={'book now'} />
                  </div>
                  <img
                    src={serv?.description[0]?.img}
                    alt='services images'
                    className='w-[130px] md:w-[150px] h-full rounded-xl shadow-lg object-cover'
                  />
                </div>
                <div>
                  {
                    rpdata?.works?.map((item, index) => {
                      if (item.service === serv.name) {
                        return (
                          <div class="">
                            <div key={index} className='w-[90%] mx-auto flex items-center text-white'>
                              <p className='w-[75%] md:w-[80%] border-dotted border-b-2 capitalize font-semibold text-[18px] mb-5'>{item.name}</p>
                              <p className='w-[25%] md:w-[20%] text-end md:text-start font-semibold'> {item.subdescription}</p>
                            </div>
                          </div>
                        )
                      }
                      return null
                    })
                  }
                </div>
              </div>

            </>



          )
        })
      }
    </div>
  );
}

export default RatesCompRec;
